<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <div class="text-h1">{{$t('project.members.MembersHeader.membersTitle')}}</div>
        <p class="mt-3 mb-0 body-1 accent--text text--lighten-2">
          {{$t('project.members.MembersHeader.membersSubtitle')}}
          <AppInfoTooltip>
            <div>{{ $t('project.members.MembersHeader.pmCanSee') }}</div>
            <div v-if="hideGroups">{{ $t('project.members.MembersHeader.guestsCanSee1') }}</div>
            <div v-else>{{ $t('project.members.MembersHeader.guestsCanSee2') }}</div>
          </AppInfoTooltip>
        </p>
      </v-col>
    </v-row>
    <v-row class="align-center my-2">
      <v-col class="d-flex align-center">
        <SearchTextField :value="searchQuery"
                         :tooltipText="$t('project.members.MembersHeader.searchPlaceholder')"
                         @input="$emit('update:searchQuery', $event)"
        />
        <span v-if="hideGroups && isCurrentUserPm" class="mx-1">
          <AppTooltip top>
            <template #activator="{attrs, on}">
              <v-chip color="#e0e5ed"
                      text-color="tertiary"
                      label
                      small
                      class="text-h5 text-uppercase"
                      v-bind="attrs"
                      v-on="on"
              >
                {{$t('project.members.MembersHeader.hiddenGroups')}}
                <font-awesome-icon :icon="['far','lock-alt']" class="ml-2"></font-awesome-icon>
              </v-chip>
            </template>
            <span>{{$t('project.members.MembersHeader.hideGroupsTooltip')}}</span>
          </AppTooltip>
        </span>
      </v-col>
      <v-col v-if="isCurrentUserPm"
             cols="auto"
      >
        <v-switch :input-value="hideGroups"
                  readonly
                  class="mt-n1"
                  hide-details
                  :loading="patchHideGroupsLoading"
                  @click="!patchHideGroupsLoading && hideGroupsHandler()"
        >
         <template #label>
           {{ $t('project.members.MembersHeader.hideGroupsLabel') }}
            <AppHelpTooltip :text="$t('project.members.MembersHeader.hideGroupsTooltip')"/>
         </template>
        </v-switch>
      </v-col>
      <v-col v-if="isCurrentUserPm"
             cols="auto"
      >
        <v-menu
          bottom
          offset-y
          max-width="300"
        >
          <template #activator="{ on, attrs }">
            <app-button type="outlined"
                        variant="neutral"
                        v-bind="attrs"
                        v-on="on"
            >
              {{ $t('project.members.MembersHeader.options') }}
              <v-icon right>fal fa-chevron-down</v-icon>
            </app-button>
          </template>
          <v-list>
            <v-list-item v-if="isCurrentUserPm" link
                         @click="importModalIsOpen = true"
            >
            <v-list-item-icon class="mr-2">
              <font-awesome-icon :icon="['fal', 'file-import']"
                                 size="lg"
              ></font-awesome-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('project.members.MembersHeader.import') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
            <AppDownloadFile :link="massExportAsExcelLink" class="d-block">
              <template #default="{ submitForm }">
                <v-list-item link @click="submitForm">
                  <v-list-item-icon class="mr-2 member-header-title-icon">
                    <app-icon icon-weight="fal" icon-name="file-export" size="medium" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('project.members.MembersHeader.massExportAsExcel') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </AppDownloadFile>
            <v-divider class="mx-4"></v-divider>
            <AppDownloadFile :link="exportAsExcelLink" class="d-block">
              <template #default="{ submitForm }">
                <v-list-item link @click="submitForm">
                  <v-list-item-icon class="mr-2 member-header-title-icon">
                  <font-awesome-icon :icon="['fal', 'file-excel']"
                                     size="lg"
                  ></font-awesome-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('project.members.MembersHeader.exportAsExcel') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </AppDownloadFile>
            <AppDownloadFile :link="exportAsWordLink" class="d-block">
              <template #default="{ submitForm }">
                <v-list-item link @click="submitForm">
                  <v-list-item-icon class="mr-2 member-header-title-icon">
                    <font-awesome-icon :icon="['fal', 'file-word']"
                                       size="lg"
                    ></font-awesome-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('project.members.MembersHeader.exportAsWord') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </AppDownloadFile>
            <AppDownloadFile v-if="hasNda" :link="exportSignedNdaLink" :isNewTab="false" class="d-block">
              <template #default="{ submitForm }">
                <v-list-item link @click="onDownloadSignedNda(submitForm)">
                  <v-list-item-icon class="mr-2 member-header-title-icon">
                    <app-icon iconWeight="fal" iconName="arrow-down-to-line" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title style="white-space: normal">
                      {{ $t('project.members.MembersHeader.downloadSignedNda') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </AppDownloadFile>
            <v-divider class="mx-4"></v-divider>
            <v-list-item v-if="isCurrentUserPm"
                         link
                         @click="sendGlobalReminder"
            >
              <v-list-item-icon class="mr-2 member-header-title-icon">
                <app-icon icon-name="envelope-open-text" size="medium" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('project.members.MembersHeader.sendGlobalReminder') }}
                  <app-text variant="x-small-regular" class="grey--text text--darken-2 mt-2"
                            style="white-space: normal;"
                  >
                    {{ $t('project.members.MembersHeader.sendGlobalReminderSubtitle') }}
                  </app-text>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <GroupImportDialog
          v-if="importModalIsOpen"
          :mnemo="mnemo"
          @onClose="importModalIsOpen = false"
        />
      </v-col>
      <v-col v-if="isCurrentUserPm" cols="auto">
        <app-button color="primary" @click="openAddGroupDialog">
          {{ $t('project.members.MembersHeader.addGroup') }}
        </app-button>
      </v-col>
    </v-row>
    <AppDialog :is-open="openHideGroupsModal"
               @cancel="openHideGroupsModal = false"
               @ok="onConfirmHideGroups"
    >
      <template #title>{{$t('project.members.MembersHeader.hideGroupsModal.title')}}</template>
      <template #body>
        {{$t('project.members.MembersHeader.hideGroupsModal.text')}}
      </template>
    </AppDialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile'
import AppHelpTooltip from '@/common/AppHelpTooltip'
import AppInfoTooltip from '@/common/AppInfoTooltip'
import AppTooltip from '@/common/AppTooltip'
import AppDialog from '@/common/dialogs/AppDialog'
import SearchTextField from '@/common/SearchTextField'
import { SET_CURRENT_ROOM } from '@/store/modules/room/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

import GroupImportDialog from './dialogs/GroupImportDialog'

export default {
  name: 'MembersHeader',
  components: {
    AppInfoTooltip,
    AppDownloadFile,
    AppDialog,
    GroupImportDialog,
    SearchTextField,
    AppHelpTooltip,
    AppTooltip,
  },
  props: {
    searchQuery: {
      type: String,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      openHideGroupsModal: false,
      importModalIsOpen: false,
    }
  },
  computed: {
    ...mapState('groups', ['patchHideGroupsLoading']),
    ...mapState('room', ['currentRoom']),
    ...mapGetters('room', ['isCurrentUserPm', 'roomMnemo']),
    massExportAsExcelLink () {
      return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/participants-excel-mass`
    },
    hasNda () {
      return this.currentRoom?.hasNda
    },
    exportAsExcelLink () {
      return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/participants-excel`
    },
    exportAsWordLink () {
      return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/participants-word`
    },
    exportSignedNdaLink () {
      return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/signed-nda`
    },
    hideGroups () {
      return this.currentRoom.hideGroups === 1
    },
  },
  methods: {
    ...mapActions('groups', ['PATCH_HIDE_GROUPS', 'SEND_GLOBAL_REMINDER']),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async sendGlobalReminder () {
      try {
        await this.SEND_GLOBAL_REMINDER({
          mnemo: this.roomMnemo,
        })
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.members.MembersHeader.sendGlobalReminderSuccess'))
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.members.MembersHeader.sendGlobalReminderError'))
      }
    },
    openAddGroupDialog () {
      this.$emit('onAddGroup')
    },
    async onDownloadSignedNda (callback) {
      if (!this.hasNda) return
      try {
        // Check that file is available, then submit form
        await axios.head(this.exportSignedNdaLink)
        callback()
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.members.MembersHeader.downloadComplete'))
      } catch (error) {
        console.error(error)
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.members.MembersHeader.downloadError'))
      }
    },
    hideGroupsHandler () {
      if (this.hideGroups) {
        this.openHideGroupsModal = true
      } else {
        this.PATCH_HIDE_GROUPS({ hideGroupsStatus: 'true' })
          .then(() => {
            this.$store.commit('enqueueSnackbar', {
              color: 'success',
              message: this.$t('project.members.MembersHeader.messages.hideGroups'),
            })
            this.currentRoom.hideGroups = 1
            this.$store.commit(`room/${SET_CURRENT_ROOM}`, this.currentRoom)
          })
      }
    },
    onConfirmHideGroups () {
      this.PATCH_HIDE_GROUPS({ hideGroupsStatus: 'false' })
        .then(() => {
          this.$store.commit('enqueueSnackbar', {
            color: 'success',
            message: this.$t('project.members.MembersHeader.messages.notHideGroups'),
          })
          this.currentRoom.hideGroups = 0
          this.$store.commit(`room/${SET_CURRENT_ROOM}`, this.currentRoom)
          this.openHideGroupsModal = false
        })
    },
  },
}
</script>

<style scoped>
.member-header-title-icon{
  justify-content: center;
}
</style>
